// 姓名脱敏
export const nameConvert = (name: string) => {
  let userName = ''
  if (name.length === 2) {
    userName = `${name.substring(0, 1)}*` // 截取name 字符串截取第一个字符，
  } else if (name.length === 3) {
    // userName = name.substring(0, 1) + "*" + name.substring(2, 3); // 截取第一个和第三个字符
    userName = `${name.substring(0, 1)}*${name.substring(2, 3)}` // 截取第一个和第三个字符
  } else if (name.length > 3) {
    userName = `${name.substring(0, 1)}**${name.substring(3, name.length)}` // 截取第一个和大于第4个字符
  }
  return userName
}

// 银行卡脱敏
export const bankCardConvert = (card: string) => card.replace(/ /g, '').replace(/^(.{3})(?:\d+)(.{4})$/, '$1***$2')

// 手机号脱敏
export const phoneConvert = (areaCode:string, phone: string) => {
  switch (areaCode) {
    case '852':
    case '853':
    case '65':
      return phone.replace(/ /g, '').replace(/(\d{2})\d*(\d{2})/, '$1****$2')
    case '886':
      return phone.replace(/ /g, '').replace(/(\d{4})\d*(\d{2})/, '$1****$2')
    default:
      return phone.replace(/ /g, '').replace(/(\d{3})\d*(\d{4})/, '$1****$2')
  }
}
