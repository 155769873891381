import dayjs from 'dayjs'
import { InputWay, AccessWay } from '@/enum'
import useOpenInfo from '@/hooks/openInfo'
// import { RECEIVING_BANK } from '@/consts/index';
import * as storage from '@/utils/storage'
import { isEmpty } from '@/utils'

export const useData = () => {
  const { openInfo, openType } = useOpenInfo()
  const data: any = {
    clientName: '',
    familyName: '',
    givenName: '',
    clientNameSpell: '',
    familyNameSpell: '',
    givenNameSpell: '',
    maritalStatus: 1,
    sex: 1,
    nationality: 'CHN',
    educationLevel: 4,
    birthday: '',
    idKind: 1,
    idNo: '',
    bankType: 2, //  银行账户类型[1-大陆银行卡 2-香港银行卡]
    // idCardValidDateStart: '', //    身份证生效日期
    idCardValidDateEnd: '', //    身份证失效日期
    idCardAddress: '',
    // cardProvinceName: '',
    // cardCityName: '',
    // cardCountyName: '',
    // cardDetailAddress: '',
    certificateIssuingCountry: '', // 证件签发国家
    // bornProvinceName: '',
    // bornCityName: '',
    // bornCountyName: '',
    familyAddress: '', // 住宅地址
    // familyProvinceName: '', // 住宅地址的省份
    // familyCityName: '', // 住宅地址的城市
    // familyCountyName: '', // 住宅地址的区域
    familyDetailAddress: '', // 住宅地址的详细地址
    // familyStreet: '', // 类型：String  必有字段  备注：街道
    // familyBuildingName: '', // 类型：String  必有字段  备注：楼栋
    // familyBlock: '', // 座
    // familyFloor: '', // 类型：String  必有字段  备注：楼层
    // familyFlat: '', // 类型：String  必有字段  单位
    // familyPostcode: '', // 类型：String  必有字段  备注：邮编
    phoneNumber: '', //  手机号码
    email: '', // 电子邮箱
    contactAddress: '', //   通讯地址
    // contactProvinceName: '', //   通讯地址的省份
    // contactCityName: '', //   通讯地址的城市
    // contactCountyName: '', //   通讯地址的区域
    contactDetailAddress: '', //   通讯地址的详细地址
    // contactStreet: '', // 类型：String  必有字段  备注：街道
    // contactBuildingName: '', // 类型：String  必有字段  备注：楼栋
    // contactBlock: '', // 座
    // contactFloor: '', // 类型：String  必有字段  备注：楼层
    // contactFlat: '', // 类型：String  必有字段  单位
    // contactPostcode: '', // 类型：String  必有字段  备注：邮编
    // postCode: '', //   邮政编号
    professionCode: 1, //    就业状态[1-受雇 2-自雇 3-学生 4-家庭主妇 5-退休 6-失业 7-其他]
    professionCodeOther: '',
    companyName: '', //   公司名称
    jobPosition: '', //   职位
    companyBusinessType: '', //   业务性质
    bizTypeDesc: '', // 其他业务性质
    // companyPhoneNumber: '', //   公司电话
    // companyWorkYears: 1, //    服务年期[1-小于1年 2-1~5年 3-6~10年 4-大于10年]
    // companyAddress: '', //   公司地址
    assetSource: [], // 资金来源[1-薪金 2-租金收入 3-投资回报 4-佣金 5-股息/利息 6-退休金 7-业务收入 8-储蓄 9-遗产 10-其他]
    assetSourceOther: '', //  其他资金来源
    annualIncome: 5, // 每年收入
    // assetSourcePlace: [], //   资产来源地[1-中国 2-香港 3-其他]
    // assetSourcePlaceOther: '', //   资产来源地其他
    assetItem: [], //  资产项目[1-房地产 2-现金/存款 3-上市股票 4-债券 5-其他]
    netAsset: 3, //  资产净值
    isAmericanGreenCardHolder: 0, //    是否美国绿卡持有人[0-否 1-是]
    investTarget: [], //  投资目标[1-股息收入 2-短期投资 3-长期投资 4-其他]
    investTargetOther: '', // 其他投资目标
    stockInvestmentExperienceType: 3,
    // 股票投资经验类型[1-没有 2-小于1年 3-1~3年 4-4~6年 5-7~9年 6-大于10年]
    bondInvestmentExperienceType: 2, // 债券投资经验类型
    warrantsInvestmentExperienceType: 1, // 衍生权证投资经验类型
    // exchangeInvestmentExperienceType: 3, // 交易所买卖产品投资经验类型
    otherInvestmentName: '',
    otherInvestmentExperienceType: 4, // 其他产品投资经验类型
    isTradeDerivativeProducts: 1,
    isKnowDerivativeProducts: 1,
    isPiVerify: 1, //    是否PI资质认证[0-否 1-是]
    openAccountTaxationList: [], //    开户税务信息
    openAccInfoDisclosureList: [], //    尽职审查披露信息
    openAccSettlementBankList: [],
    openAccDerivativeProductList: [], // 衍生品
    openAccountFundDeposit: {},
    kindSource: openType.value,
    assetItemOther: '', // 资产项目其他
    expectedYearInvestmentAmount: 1,
    licensedTime: '',
    callValue: '', // 称谓
    trustInvestmentExperienceType: 1, // 单位信托
    otcInvestmentExperienceType: 1, // 结构性
    annualIncomeOther: '', // 每年收入，大于1亿填值
    netAssetOther: '', // 资产净值，大于1亿填值
    expectedYearInvestmentAmountOther: '',
    piAssetCriteriaList: [], // PI资产准则（个人）
    piAssetFileList: [], // PI资产证明材料（个人）
    derivativeInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：衍生权证
    bullBearInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：牛熊证
    etfInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：交易所买卖基金
    leverInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：杠杆及反向产品
    fcnInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：固定派息票据
    elnInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：股票挂钩票据
    aqDqInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：累计认购期权/累计认沽期权
    pbInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：参与式票据
    ibInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：区间票据
    trsInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：总收益互换
    scnInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：雪球派息票据
    otcOptionInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：场外期权
    trustFundInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：单位信托或互惠基金
    privateFundInvestmentExperienceType: 1, // 类型：Number  必有字段  备注：私募基金
    otherInvestment: [
      {
        otherInvestmentExperienceType: 2,
        otherInvestmentName: ''
      }
    ], // 其他投资经验
    isSetSubAccount: 1,
    subAccountNum: 2,
    subAccountRatio: [50, 50]
  }
  // 客户声明;
  const cusList = [30, 31, 32, 33, 34, 35]
  let disclosureType4: { disclosureType: number; disclosureCode: number; disclosureIsTrue: number }[] = []
  if (openInfo.value?.isPiVerify === 1) {
    disclosureType4 = cusList.map((item: number) => ({
      disclosureType: 4,
      disclosureCode: item,
      disclosureIsTrue: 1
    }))
  }
  // 个人资料使用
  const disclosureType1 = [
    { disclosureType: 1, disclosureCode: 12, disclosureIsTrue: 1 },
    { disclosureType: 1, disclosureCode: 36, disclosureIsTrue: openInfo.value?.disclosureType1.includes(36) ? 1 : 0 },
    { disclosureType: 1, disclosureCode: 37, disclosureIsTrue: openInfo.value?.disclosureType1.includes(37) ? 1 : 0 },
    { disclosureType: 1, disclosureCode: 38, disclosureIsTrue: openInfo.value?.disclosureType1.includes(38) ? 1 : 0 }
  ]
  // if (openInfo.value?.disclosureType1.includes(13)) {
  //   disclosureType1 = [
  //     { disclosureType: 1, disclosureCode: 12, disclosureIsTrue: 1 },
  //     { disclosureType: 1, disclosureCode: 13, disclosureIsTrue: 1 }
  //   ]
  // }
  // 风险披露
  let disclosureCode14: any = {}
  let disclosureCode15: any = {}
  let disclosureCode16: any = {}
  let { checked } = openInfo.value
  if (checked === undefined) {
    checked = checked.value
  }
  if (checked.includes(14)) {
    disclosureCode14 = {
      disclosureType: 2,
      disclosureCode: 14,
      disclosureIsTrue: 1
    }
  } else {
    Object.keys(openInfo.value.disclosureCode14).forEach(val => {
      disclosureCode14[val] = openInfo.value.disclosureCode14[val]
    })
    delete disclosureCode14.areaCode
    delete disclosureCode14.phone
    delete disclosureCode14.nation
    delete disclosureCode14.country
    disclosureCode14.disclosure6 = `${openInfo.value.disclosureCode14.areaCode}-${openInfo.value.disclosureCode14.phone}`
    disclosureCode14.disclosure2 = dayjs(disclosureCode14.disclosure2)
      .valueOf()
      .toString()
  }
  if (checked.includes(15)) {
    disclosureCode15 = {
      disclosureType: 2,
      disclosureCode: 15,
      disclosureIsTrue: 1
    }
  } else {
    Object.keys(openInfo.value.disclosureCode15).forEach(val => {
      disclosureCode15[val] = openInfo.value.disclosureCode15[val]
    })
    delete disclosureCode15.areaCode
    delete disclosureCode15.phone
    delete disclosureCode15.nation
    delete disclosureCode15.country
    disclosureCode15.disclosure6 = `${openInfo.value.disclosureCode15.areaCode}-${openInfo.value.disclosureCode15.phone}`
    disclosureCode15.disclosure2 = dayjs(disclosureCode15.disclosure2)
      .valueOf()
      .toString()
  }
  if (checked.includes(16)) {
    disclosureCode16 = {
      disclosureType: 2,
      disclosureCode: 16,
      disclosureIsTrue: 1
    }
  } else {
    Object.keys(openInfo.value.disclosureCode16).forEach(val => {
      disclosureCode16[val] = openInfo.value.disclosureCode16[val]
    })
    delete disclosureCode16.areaCode
    delete disclosureCode16.phone
    delete disclosureCode16.nation
    delete disclosureCode16.country
    disclosureCode16.disclosure6 = `${openInfo.value.disclosureCode16.areaCode}-${openInfo.value.disclosureCode16.phone}`
    disclosureCode16.disclosure2 = dayjs(disclosureCode16.disclosure2)
      .valueOf()
      .toString()
  }

  const disclosureType2 = [disclosureCode14, disclosureCode15, disclosureCode16]
  // 针对非香港卡的已经上传开户的默认勾选
  // if (
  //   openInfo.value.idKind !== 2 &&
  //   openInfo.value.idKind !== 3 &&
  //   !openInfo.value.disclosureCode24
  // ) {
  //   saveOpenInfoOfVuex({
  //     info: {
  //       disclosureCode24: {
  //         disclosureType: 3,
  //         disclosureCode: 24,
  //         disclosureIsTrue: 0
  //       }
  //     }
  //   })
  // }
  // if (openInfo.value.idKind === 2 || openInfo.value.idKind === 3) {
  //   saveOpenInfoOfVuex({
  //     info: {
  //       disclosureCode24: {
  //         disclosureType: 3,
  //         disclosureCode: 24,
  //         disclosureIsTrue: 1,
  //         disclosure1: openInfo.value.idNo
  //       }
  //     }
  //   })
  // }
  const disclosureType3: any = []
  const checked1 = [...checked]
  const riskList = [1, 28, 27, 3, 4, 5, 6, 7, 8]
  const riskList2 = [14, 15, 16]
  riskList2.forEach(item => {
    if (checked1.indexOf(item) !== -1) {
      checked1.splice(checked1.indexOf(item), 1)
    }
  })
  riskList.forEach((item: number) => {
    if (checked1.includes(item)) {
      disclosureType3.push({
        disclosureType: 3,
        disclosureCode: item,
        disclosureIsTrue: 0
      })
    } else {
      disclosureType3.push(openInfo.value[`disclosureCode${item}`])
    }
  })
  // 之前没有的默认当没勾选传值
  if (!checked1.includes(28)) {
    disclosureType3.push({
      disclosureType: 3,
      disclosureCode: 28,
      disclosureIsTrue: 1
    })
  }
  // 兼容老数据判断
  const isExit27 = disclosureType3?.filter((item: any) => item)?.find((v: any) => v?.disclosureCode === 27)
  if (!checked1.includes(27) && !isExit27) {
    disclosureType3.push({
      disclosureType: 3,
      disclosureCode: 27,
      disclosureIsTrue: 1
    })
  }

  // 如果是投资移民开户
  if (storage.get('formType') === 'openImmigrantAccount') {
    disclosureType3.push({
      disclosureType: 3,
      disclosureCode: 29,
      disclosureIsTrue: checked1.includes(29) ? 0 : 1,
      disclosure1: !checked1.includes(29) ? openInfo.value.disclosureCode29.disclosure1 : '',
      disclosure2: !checked1.includes(29) ? openInfo.value.disclosureCode29.disclosure2 : ''
    })
  }

  openInfo.value.openAccInfoDisclosureList = [
    ...disclosureType1,
    ...disclosureType2,
    ...disclosureType3.filter((item: any) => item),
    ...disclosureType4
  ]

  Object.keys(data).forEach(val => {
    if (!isEmpty(openInfo.value[val])) {
      data[val] = openInfo.value[val]
    }
  })
  // 衍生品
  if (
    // openInfo.value?.isTradeDerivativeProducts === 0 ||
    openInfo.value?.isKnowDerivativeProducts === 0
  ) {
    data.openAccDerivativeProductList = []
  } else {
    const derivativeCode9: any = { derivativeCode: 9, derivativeValue: 0 }
    const derivativeCode10: any = { derivativeCode: 10, derivativeValue: 0 }
    if (openInfo.value?.derivativeCode9.derivativeValue === 1) {
      Object.keys(openInfo.value?.derivativeCode9).forEach(key => {
        derivativeCode9[key] = openInfo.value?.derivativeCode9[key]
      })
      derivativeCode9.derivativeDetail3 = derivativeCode9.derivativeDetail3
        ? dayjs(derivativeCode9.derivativeDetail3).valueOf().toString()
        : ''
    }
    if (openInfo.value?.derivativeCode10.derivativeValue === 1) {
      Object.keys(openInfo.value?.derivativeCode10).forEach(key => {
        derivativeCode10[key] = openInfo.value?.derivativeCode10[key]
      })
      delete derivativeCode10.startDate
      delete derivativeCode10.endDate
      console.log(derivativeCode10.derivativeDetail2)
    }
    data.openAccDerivativeProductList = [
      derivativeCode9,
      derivativeCode10,
      openInfo.value?.derivativeCode11
    ]
  }
  // 税务信息
  data.openAccountTaxationList = data.openAccountTaxationList.map((item: any) => {
    if (item.hasTaxNumber === 1) {
      return {
        taxCountry: item.taxCountry,
        taxNumber: item.taxNumber,
        hasTaxNumber: item.hasTaxNumber
      }
    }
    return {
      taxCountry: item.taxCountry,
      reasonType: item.reasonType,
      reasonDesc: item.reasonDesc,
      hasTaxNumber: item.hasTaxNumber
    }
  })
  data.openAccountInputWay = InputWay.ONLINE
  data.openAccountAccessWay = AccessWay.H5
  data.openAccountType = storage.get('formType') === 'openImmigrantAccount' ? 9 : 1
  data.kindSource = openType.value
  data.fundAccountType = storage.get('formType') === 'openImmigrantAccount' ? [4] : [1, 2]
  data.bankType = 2
  data.clientName = openInfo.value.familyName + openInfo.value.givenName
  data.clientNameSpell = `${openInfo.value.familyNameSpell} ${openInfo.value.givenNameSpell}`
  data.sex = Number(openInfo.value.sex)
  data.idCardValidDateEnd =
    openInfo.value.idCardValidDateEnd === '长期'
      ? '永久'
      : dayjs(openInfo.value.idCardValidDateEnd).valueOf().toString()
  data.birthday = dayjs(openInfo.value.birthday)
    .valueOf()
    .toString()
  data.receiveStatementWay = [1]
  data.bornProvinceName = openInfo.value?.birthPlace.split('/')[0]
  data.bornCityName = openInfo.value?.birthPlace.split('/')[1]
  data.bornCountyName = openInfo.value?.birthPlace.split('/')[2]
  if (data.bornProvinceName === '其他') {
    data.bornProvinceName = openInfo.value?.birthPlaceOther
  }
  if (openInfo.value?.familyAddressPre) {
    data.familyProvinceName = openInfo.value?.familyAddressPre.split('/')[0]
    data.familyCityName = openInfo.value?.familyAddressPre.split('/')[1]
    data.familyCountyName = openInfo.value?.familyAddressPre.split('/')[2]
    // 处理地址选择其他的情况
    if (openInfo.value?.familyAddressPre === '其他') {
      data.familyProvinceName = openInfo.value?.familyAddressOther
    }
    // data.familyAddress = data.familyProvinceName + data.familyCityName + data.familyCountyName + openInfo.value?.familyDetailAddress
  }
  if (openInfo.value?.contactAddress === openInfo.value?.familyAddress) {
    if (openInfo.value?.familyAddressPre) {
      data.contactProvinceName = openInfo.value?.familyAddressPre.split('/')[0]
      data.contactCityName = openInfo.value?.familyAddressPre.split('/')[1]
      data.contactCountyName = openInfo.value?.familyAddressPre.split('/')[2]
      data.contactDetailAddress = openInfo.value?.familyAddress.split(data.contactCountyName)[1]
      if (openInfo.value?.familyAddressPre === '其他') {
        data.contactProvinceName = openInfo.value?.familyAddressOther
        data.contactDetailAddress = openInfo.value?.familyDetailAddress
      }
    }
  } else if (openInfo.value?.contactAddressPre) {
    data.contactProvinceName = openInfo.value?.contactAddressPre.split('/')[0]
    data.contactCityName = openInfo.value?.contactAddressPre.split('/')[1]
    data.contactCountyName = openInfo.value?.contactAddressPre.split('/')[2]
    // data.contactAddress = data.contactProvinceName + data.contactCityName + data.contactCountyName + openInfo.value?.contactDetailAddress
    // 处理地址选择其他的情况
    if (openInfo.value?.contactAddressPre === '其他') {
      data.contactProvinceName = openInfo.value?.contactAddressOther
    }
  }

  data.otherInvestment = data.otherInvestment?.map((v:any) => ({
    otherInvestmentExperienceType: v.otherInvestmentExperienceType,
    otherInvestmentName: v.otherInvestmentName
  }))

  const userInfo: any = storage.get('userInfo')
  data.phoneNumber = `${userInfo.userInfo.areaCode}-${userInfo.userInfo.phone}`

  if (data.subAccountRatio && data.openAccountType === 9) {
    data.subAccountRatio = JSON.stringify(data.subAccountRatio?.map((v: any) => {
      if (typeof v === 'number') {
        return +v
      }
      return +v?.split('%')?.[0]
    }))
  } else {
    data.subAccountRatio = '[]'
    data.subAccountNum = null
  }

  data.otherInvestment = openInfo.value?.otherInvestment.map((v:any) => ({
    otherInvestmentExperienceType: v.otherInvestmentExperienceType,
    otherInvestmentName: v.otherInvestmentName
  }))
  if (openInfo.value?.otherInvestmentName) {
    data.otherInvestment = [{
      otherInvestmentExperienceType: data.otherInvestmentExperienceType,
      otherInvestmentName: data.otherInvestmentName
    }]
  }

  console.log('data', data)
  return data
}
