import { queryFn } from '@/graphql'
import { ref } from 'vue'
import * as storage from '@/utils/storage'
import { useRoute } from 'vue-router'

const openAccountTypeMap:any = {
  openAccount: 1, // 普通开户
  openImmigrantAccount: 9 // 移民开户
}

function useQueryFileTemp<P> (params: P) {
  const route = useRoute()
  const result = ref()
  const formType = route?.query?.type || storage.get('formType') || 'openAccount'
  queryFn('fileTempQuery', {
    ...params,
    openAccountType: openAccountTypeMap[formType as string] || 1
  }).then(res => {
    result.value = res.data
  })
  return result
}

export default useQueryFileTemp
