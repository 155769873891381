import { queryApi } from './query'
import { mutateApi } from './mutation'
import apolloClient from '@/apollo-client'

export async function queryFn<T> (queryName: string, variables: T, fetchPolicy: any = 'no-cache') {
  const req = await apolloClient
    .query({
      query: queryApi[queryName],
      variables: { params: variables },
      fetchPolicy
    })
    .then((response: any) => {
      // console.log(response, 'response')
      return response?.data[queryName]
    })
    .catch(error => {
      console.log(error.graphQLErrors)
    })

  return req
}
export async function queryFnByFilter<T> (queryName: string, variables: T, fetchPolicy: any = 'no-cache') {
  const req = await apolloClient
    .query({
      query: queryApi[queryName],
      variables: { filter: variables },
      fetchPolicy
    })
    .then((response: any) => {
      // console.log(response, 'response')
      return response?.data[queryName]
    })
    .catch(error => {
      console.log(error.graphQLErrors)
    })

  return req
}
export async function mutateFn<T> (queryName: string, variables: any) {
  const req = await apolloClient
    .mutate({
      mutation: mutateApi[queryName],
      variables: variables,
      fetchPolicy: 'network-only'
    })
    .then(response => {
      // console.log(response, 'response');
      return response?.data[queryName]
    })
    .catch(error => {
      console.log(error.graphQLErrors)
    })

  return req
}
