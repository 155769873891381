import gql from 'graphql-tag'

export const mutateApi: any = {
  // 上传图片
  uploadFile: gql`
    mutation uploadFile($file: Upload!) {
      uploadFile(file: $file) {
        data
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  //  删除临时文件
  deleteTempFile: gql`
    mutation deleteTempFile($params: DeleteTempFileInput!) {
      deleteTempFile(param: $params) {
        data
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  //  发送验证码
  getSmsVaild: gql`
    mutation getSmsVaild($params: ParamGetSmsVaildInput!) {
      getSmsVaild(param: $params) {
        data {
          resBool
        }
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  // 开户上传图片
  openAccountUploadFile: gql`
    mutation openAccountUploadFile($params: OpenAccountUploadFileInput!) {
      openAccountUploadFile(param: $params) {
        data
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  // 提交开户表格
  openInfoSave: gql`
    mutation openInfoSave($param: OpenInfoSaveInput!) {
      openInfoSave(param: $param) {
        data
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  //   发送验证码
  registerDevice: gql`
    mutation registerDevice($params: ParamReigsterDeiveInput!) {
      registerDevice(param: $params) {
        data {
          deviceId
          createTime
        }
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  //   保存草稿
  saveClientFormDraft: gql`
    mutation saveClientFormDraft($params: ParamClientFormDraftIntput!) {
      saveClientFormDraft(param: $params) {
        ok
      }
    }
  `,
  //   发送验证码
  sendPhoneSms: gql`
    mutation sendPhoneSms($params: ParamSendPhoneSmsInput!) {
      sendPhoneSms(param: $params) {
        data {
          resBool
        }
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  // 用户操作
  clientOperationRecord: gql`
    mutation clientOperationRecord($params: ClientOperationRecordInput!) {
      clientOperationRecord(param: $params) {
        code
        message
        result
      }
    }
  `,
  // 待上传转账凭证
  uploadTransferVoucher: gql`
    mutation uploadTransferVoucher($param: uploadTransferVoucherInput!) {
      uploadTransferVoucher(param: $param) {
        data
        ok
        reason {
          err
          desc
        }
      } 
    }
  `
}
