import { mutateFn } from '@/graphql'
import * as storage from '@/utils/storage'

const useSaveOpenInfo = async <T>(params: T) => {
  return await mutateFn('saveClientFormDraft', {
    params: {
      ...params,
      formType: storage.get('formType')
    }
  })
}
export default useSaveOpenInfo
