
import { computed, defineComponent, onMounted, reactive, Ref, ref, watch } from 'vue'
import { Dialog, ImagePreview } from 'vant'
import { nanoid } from 'nanoid'
import { useRouter } from 'vue-router'
import { useToggle } from '@vant/use'
import uploadApi from '@/axios/upload'
import { ImageType, BusinessType, Source, CameraOptions, RouterName, OpenStatus } from '@/enum'
import useOpenInfo from '@/hooks/openInfo'
import { useData } from './hooks/index'
import useQueryFileTemp from '@/hooks/useFileTemp'
import useSaveOpenInfo from '@/hooks/useSaveOpenInfo'
import { RECEIVING_BANK } from '@/consts/index'
import { bankCardConvert } from '@/utils/tuomin'
import {
  getPictureAction,
  setApptitle,
  isInAppWebview,
  appUploadFileAction,
  appCloseWebView
} from '@/utils/native-app'
import { dataURLtoFile, unzip } from '@/utils/file'
import beforeUpload from '@/utils/before-upload'
import { FileModel } from '@/interface'
import { mutateFn } from '@/graphql'
import { Message } from '@/components/Message'
import useCheckIp from '@/hooks/useCheckIp'
import { CustomDialog } from '@/components/CustomDialog'
import { useMutation, useQuery } from '@vue/apollo-composable'
import * as queryApi from '@/graphql/query/index'
import { mutateApi } from '@/graphql/mutation/index'
import * as storage from '@/utils/storage'

export default defineComponent({
  setup () {
    const router = useRouter()
    const { openInfo, openType, meta, title, saveOpenInfoOfVuex } = useOpenInfo()
    const form: any = reactive({
      noticeBalance: '',
      noticeMoneyType: ''
    })
    const bankNo = openInfo.value?.bankNo
    const bankName =
      openInfo.value?.bankName === '其他银行'
        ? openInfo.value?.otherBankName
        : openInfo.value?.bankName
    // 图片
    const fileList: Ref<FileModel[]> = ref([])

    const applicationStatus = ref()

    // 上传文件
    const [fileActionState, fileActionToggle] = useToggle()

    // 相机选项
    const actionsList = [
      { key: CameraOptions.CAMERA, name: '拍摄' },
      { key: CameraOptions.PHOTO, name: '相册' },
      { key: CameraOptions.FILE, name: '选取文件' }
    ]

    const params = {
      paramsDic: {
        markList: ['MONEY_TYPE']
      },
      params: {
        openAccountType: storage.get('formType') === 'openImmigrantAccount' ? 9 : 1
      }
    }

    const { onResult } = useQuery(queryApi.queryTransferPro, params)

    const { mutate: handleUpLoad, onDone: handleUpLoadDone } = useMutation(mutateApi.uploadTransferVoucher)
    const { mutate: handleSaveInfo, onDone: handleSaveInfoDone } = useMutation(mutateApi.openInfoSave)

    onResult((res) => {
      const { customerOpenStatus, findDictOpenAccount } = res.data
      if (customerOpenStatus?.ok) {
        console.log('customerOpenStatus', customerOpenStatus)
        applicationStatus.value = customerOpenStatus?.data?.applicationStatus
      } else {
        Message({ text: customerOpenStatus?.reason?.desc || '请求异常', direction: 'vertical' })
      }
      if (findDictOpenAccount?.ok) {
        moneyOptions.value = findDictOpenAccount?.data.MONEY_TYPE
          .filter(
            (val: { value: string }) => val.value !== '0'
          )
      } else {
        Message({ text: findDictOpenAccount?.reason?.desc || '请求异常', direction: 'vertical' })
      }
    })

    // 用户信息回填
    const initData = async () => {
      Object.keys(form).forEach(val => {
        const res = openInfo.value[val] ? openInfo.value[val] : form[val]
        form[val] = res
      })
    }

    // 判断是否在金马app内
    const isApp = computed(() => isInAppWebview())

    const uploadToApp = () => {
      if (!isApp.value) {
        return
      }
      fileActionToggle(true)
    }

    // 上传图片
    const uploadFile = (file: File) => {
      beforeUpload(file).then((res: any) => {
        const formatFile = new File([res], res.name, { type: res.type })
        fileList.value.push({
          url: '',
          status: 'uploading',
          message: '上传中...'
        })
        const params = {
          file: formatFile,
          imageType: ImageType.ACC_IMAGE_TYPE_601,
          businessType: BusinessType.ACC_FILE_BUSINESS_TYPE_9,
          kindSource: openType.value,
          source: Source.H5
        }
        uploadApi.uploadFile(params).then((res: any) => {
          const { openAccountUploadFile } = res
          if (openAccountUploadFile) {
            fileList.value[fileList.value.length - 1] = {
              url: openAccountUploadFile.data,
              status: 'done'
            }
          } else {
            console.log('上传失败')
            Message({
              direction: 'vertical',
              text: openAccountUploadFile?.reason?.desc || '上传失败，请重试'
            })
          }
        })
      })
    }

    // 上传图片前钩子
    const beforeRead = (file: File) => {
      uploadFile(file)
    }
    const onFileSelect = async (item: { key: string }) => {
      if (item.key === CameraOptions.FILE) {
        const params = {
          imageType: ImageType.ACC_IMAGE_TYPE_601,
          businessType: BusinessType.ACC_FILE_BUSINESS_TYPE_9,
          kindSource: openType.value,
          source: Source.H5
        }
        fileList.value.push({
          url: '',
          status: 'uploading',
          message: '上传中...'
        })
        const { filePath }: any = await appUploadFileAction(params)
        if (filePath.length) {
          fileList.value[fileList.value.length - 1] = {
            url: filePath[0],
            status: 'done'
          }
        } else {
          fileList.value.pop()
        }
      } else {
        const url = await getPictureAction(item.key)
        const base64 = unzip(url)
        const imgUrl = `data:image/png;base64,${base64}`
        const png = dataURLtoFile(imgUrl, `${nanoid()}.png`)
        uploadFile(png)
      }
    }
    // 银行卡号脱敏
    const bankCardConvertFunc = computed(() => bankCardConvert(bankNo))

    // 获取缓存图片信息
    const imagesList = useQueryFileTemp({
      kindSource: openType.value
    })
    watch(
      () => imagesList.value,
      newValue => {
        fileList.value = newValue
          .filter((val: { imageType: number }) => val.imageType === ImageType.ACC_IMAGE_TYPE_601)
          .map((val: { remoteUrl: string }) => ({ url: val.remoteUrl, status: 'done' }))
      }
    )

    // 删除图片
    const handleDel = async (file: { url: string }) => {
      const params = {
        kindSource: openType.value,
        remoteUrl: file.url
      }
      const res = await mutateFn('deleteTempFile', { params })
      const { ok, reason } = res
      if (ok) {
        fileList.value = fileList.value.filter((val: { url: string }) => val.url !== file.url)
        return true
      } else {
        Message({ text: reason?.desc || '请求异常', direction: 'vertical' })
        return false
      }
    }

    const isDisabled = computed(() => {
      const formData = Object.values(form).every(item => item !== '')
      return !(
        formData &&
        fileList.value.length &&
        !fileList.value.some((s: FileModel) => s.status === 'uploading')
      )
    })

    const data = useData()

    const { isCn } = useCheckIp()

    const handleNext = () => {
      if (isCn.value) {
        // IP地址弹窗
        return CustomDialog({
          title: '您当前位于：中国内地',
          confirmText: '我知道了',
          content:
            '非常抱歉，目前无法为中国境内投资者提供开户服务。如有任何查询，请致电 (852) 2153 3838 或电邮至cs@igoldhorse.com与客户服务部查询。'
        }).then(() => {
          //
        })
      }
      if (
        !openInfo.value.callValue ||
        !data.licensedTime ||
        (data.annualIncome === 6 && !data.annualIncomeOther) ||
        (data.netAsset === 7 && !data.netAssetOther) ||
        (data.expectedYearInvestmentAmount === 7 && !data.expectedYearInvestmentAmountOther) ||
        data.otcInvestmentExperienceTypeText ||
        data.trustInvestmentExperienceTypeText ||
        (openInfo.value?.isPiVerify === 1 &&
          (!data?.piAssetCriteriaList.length || !data?.piAssetFileList.length))
      ) {
        Dialog.confirm({
          title: '资料补充',
          message: '根据合规要求，客户资料可能会不时增/减，请及时补填',
          cancelButtonText: '取消',
          confirmButtonText: '去补填'
        })
          .then(() => {
            if (!openInfo.value.callValue) {
              router.push({
                name: 'id-card-info'
              })
            } else if (!data.licensedTime) {
              router.push({
                name: 'risk'
              })
            } else if (
              (data.annualIncome === 6 && !data.annualIncomeOther) ||
              (data.netAsset === 7 && !data.netAssetOther) ||
              (data.expectedYearInvestmentAmount === 7 &&
                !data.expectedYearInvestmentAmountOther) ||
              data.otcInvestmentExperienceTypeText ||
              data.trustInvestmentExperienceTypeText
            ) {
              router.push({
                name: 'financial-info'
              })
            } else if (
              openInfo.value?.isPiVerify === 1 &&
              (!data?.piAssetCriteriaList.length || !data?.piAssetFileList.length)
            ) {
              router.push({
                name: 'pi-ar'
              })
            }
          })
          .catch(() => {
            // on cancel
          })
        return
      }
      const params = {
        formType: 'openAccount',
        meta: meta.value,
        content: JSON.stringify(form)
      }
      useSaveOpenInfo(params).then(() => {
        saveOpenInfoOfVuex({
          info: {
            noticeBalance: parseFloat(form.noticeBalance),
            noticeMoneyType: Number(form.noticeMoneyType)
          }
        })
        console.log('提交开户数据')
      })
      //   结算银行;
      data.openAccSettlementBankList = openInfo.value?.moneyType.map((val: any) => ({
        moneyType: val,
        bankName:
          openInfo.value?.swiftCode !== 'OTHER'
            ? openInfo.value?.bankName
            : openInfo.value?.otherBankName,
        bankNo: openInfo.value?.bankNo,
        bankAccount: data.clientNameSpell,
        swiftCode: openInfo.value?.swiftCode
      }))
      // 汇款信息
      let rBank = RECEIVING_BANK.filter((item, index) => Number(form.noticeMoneyType) === index)
      if (rBank.length === 0) {
        rBank = [RECEIVING_BANK[0]]
      }
      data.openAccountFundDeposit = {
        depositBank: data.openAccSettlementBankList[0]?.bankName,
        depositSwiftCode: data.openAccSettlementBankList[0]?.swiftCode,
        depositNo: data.openAccSettlementBankList[0]?.bankAccount,
        remittanceType: 1,
        noticeBalance: parseFloat(form.noticeBalance),
        noticeMoneyType: Number(form.noticeMoneyType),
        benefitBank: rBank[0].benefitBank,
        benefitNo: rBank[0].benefitNo,
        swiftCode: rBank[0].swiftCode,
        depositBankId: rBank[0].depositBankId
      }

      if (applicationStatus.value === OpenStatus.REVIEW) { // 1 审核中
        Message({ text: '提交失败，业务在后台已补充凭证。', direction: 'vertical' })
        if (isApp.value) {
          appCloseWebView()
        } else {
          router.push('/')
        }
      } else if (applicationStatus.value === OpenStatus.SUPPLEMENT) { // 13 待上传转账凭证
        const param = {
          openAccountType: storage.get('formType') === 'openImmigrantAccount' ? 9 : 1,
          openAccSettlementBankList: data.openAccSettlementBankList,
          openAccountFundDeposit: data.openAccountFundDeposit
        }
        handleUpLoad({ param })
      } else {
        const param = {
          ...data,
          depositStatus: 1
        }
        handleSaveInfo({ param })
      }
    }

    handleUpLoadDone((res: any) => {
      const { ok, reason } = res?.data?.uploadTransferVoucher
      if (ok) {
        Message({ text: '提交成功', direction: 'vertical', type: 'success' })
        router.push({ name: RouterName.REASON_STATUS, query: { status: 'done' } })
      } else {
        Message({ text: reason?.desc || '请求异常', direction: 'vertical' })
      }
    })

    handleSaveInfoDone((res: any) => {
      const { ok, reason } = res?.data?.openInfoSave
      if (ok) {
        Message({ text: '提交成功', direction: 'vertical', type: 'success' })
        router.push({ name: RouterName.REASON_STATUS, query: { status: 'done' } })
      } else {
        Message({ text: reason?.desc || '请求异常', direction: 'vertical' })
      }
    })

    // 币种类型
    const moneyOptions = ref()
    onMounted(() => {
      initData()
      setApptitle(title)
    })
    const viewExp = () => {
      ImagePreview({
        images: [
          require('../../assets/images/1apptransfer.png'),
          require('../../assets/images/2webtransfer.png')
        ],
        closeable: true
      })
    }
    return {
      viewExp,
      initData,
      isDisabled,
      handleNext,
      form,
      bankNo,
      bankName,
      fileList,
      moneyOptions,
      handleDel,
      bankCardConvertFunc,
      beforeRead,
      uploadToApp,
      isApp,
      fileActionState,
      actionsList,
      onFileSelect
    }
  }
})
