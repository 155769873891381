import { useQuery } from '@vue/apollo-composable'
import { checkIpLocation } from '@/graphql/query'
import { ref } from 'vue'

export default function useCheckIp () {
  const { onResult } = useQuery(checkIpLocation)
  const isCn = ref(true)
  onResult(res => {
    const { checkIpLocation } = res.data
    if (!checkIpLocation.ok) {
      isCn.value = false
    }
  })
  return {
    isCn
  }
}
