// 确认提示框
/**
 * @description 确认提示框
 * @author ***
 * @date ***
 * @export
 * @param {string} text 提示文字
 * @param {Function} onConfirm 确认回调
 * @param {Function} onCancel 取消回调
 * @param {string} position 提示位置
 * @returns
 * @example
 */
import { createVNode, render } from 'vue'
import DialogE from './index.vue'
interface DialogOptions {
  title?: string
  cancelText?: string
  confirmText?: string
  content?: string
}
const div = document.createElement('div')
div.setAttribute('class', 'dialog-container')
document.body.appendChild(div)
export const CustomDialog = (options: DialogOptions) => {
  return new Promise((resolve, reject) => {
    // 点击取消触发的函数
    const onCancel = () => {
      render(null, div)
      reject(Error('cancel'))
    }
    // 点击确认触发的函数
    const onConfirm = (id: string) => {
      resolve(id)
      render(null, div)
    }
    const vn = createVNode(DialogE, { ...options, onConfirm, onCancel })
    render(vn, div)
  })
}
